import TimeMe from 'timeme.js'
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// reference: https://fingerprint.com/github/
const fpPromise = FingerprintJS.load();
let cachedFingerprint = null;
// Get the visitor identifier when you need it.
export const get_fingerprint = async () => {
  if (cachedFingerprint) {
    return Promise.resolve(cachedFingerprint);
  }
  return new Promise((resolve, reject) => {
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        cachedFingerprint = result;
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
  });
};

const getPageMeta = () => {
  const body = $('body');

  const temp = {
    mode: body.data('mode'),
    brand: body.data('brand'),
    store: body.data('store'),
    category: body.data('category'),
    size: body.data('size'),
    color: body.data('color'),
    sort: body.data('sort'),
    product: body.data('product'),
    controller: body.data('controller'),
  };

  for (let prop in temp) {
    if (temp[prop] === undefined) {
      delete temp[prop];
    }
  }

  return temp;
};

const getPageType = (meta, href = window.location.href) => {
  const { controller, product } = meta;
  const url = new URL(href);
  const { pathname } = url;

  if (pathname === '/cart') {
    return 'cart';
  } else if (pathname === '/checkout') {
    return 'checkout';
  } else {
    if (controller === 'spree/products') {
      if (product) {
        return 'detail';
      } else {
        return 'shopping';
      }
    } else {
      return controller;
    }
  }
};

const getCommonData = () => {
  const page_url = window.location.href;
  const attributes = {};

  const page_meta_data = getPageMeta();
  const page_type = getPageType(page_meta_data);

  return {
    page_url,
    attributes,
    page_type,
    page_meta_data,
  };
};

const dataReport = async (
  eventName,
  info,
  code = 'garmentory-user-behavior',
  platform = 'garmentory'
) => {
  const { visitorId } = await get_fingerprint();
  const currentTime = Date.now();
  const shop_domain = window.location.host;
  const body = {
    code,
    eventInfo: JSON.stringify({
      ...info,
      did: visitorId,
      eventName,
      event_ts: currentTime,
      eventTime: currentTime,
      shop_domain,
    }),
    platform,
  };
  console.log('body', JSON.stringify(body));
  // return
  fetch(process.env.REPORT_AWS_URL, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    keepalive: true
  }).catch((e) => {
    console.log('REPORT EVENT ERROR', e.message);
  });
};

export default function () {
  if ($ === undefined || $ === null) {
    return;
  }

  const { page_url, attributes, page_type, page_meta_data } = getCommonData();
  console.log(page_type)

  if(page_type === 'detail'){
    try{
      const product_description = document.querySelector('.product-detail__accordion-item-wrap > div.product-detail__accordion-item-content > div > section > p:nth-child(1)').innerHTML
      const product_title = document.querySelector('.product-detail__product-summary-wrap > div > h1 > span').innerHTML;
      const vendor = document.querySelector('.product-detail__product-summary-wrap > div > h1 > div > a').innerHTML
      
      dataReport('product-data-onload', {
        product_id: page_meta_data.product,
        product_url: page_url,
        product_title,
        product_description,
        vendor
      }, 'data-report')
    }catch(e){
      console.log(e)
    }
  }

  // Initialize library and start tracking time
  TimeMe.initialize({
    currentPageName: page_type, // current page
    idleTimeoutInSeconds: 10 // seconds
  });

  window.onbeforeunload = function (event) {
    let timeSpentOnPage = TimeMe.getTimeOnCurrentPageInSeconds();
    dataReport('time-on-page', {
      page_url,
      attributes,
      page_type,
      page_meta_data,
      duration: timeSpentOnPage
    });
  };

  $(document).on('ajaxSend', function (event, xhr, settings) {});

  $(document).on('ajaxComplete', function (event, xhr, settings) {
    const { url, data } = settings;
    const fullUrl = new URL(url, window.location.origin);
    const form = new URLSearchParams(data);
    const params = Object.fromEntries(form.entries());
    const { pathname, search } = fullUrl;
    const searchs = new URLSearchParams(search);
    const querys = Object.fromEntries(searchs.entries());
    switch (pathname) {
      case '/remove_favorite_product.js':
        dataReport('remove-favorite', {
          page_url,
          attributes,
          page_type,
          page_meta_data,
          ...params,
          ...querys,
        });
        break;
      case '/add_favorite_product.js':
        dataReport('add-favorite', {
          page_url,
          attributes,
          page_type,
          page_meta_data,
          ...params,
          ...querys,
        });
        break;
      case '/add_to_cart':
        dataReport('add-to-cart', {
          page_url,
          attributes,
          page_type,
          page_meta_data,
          ...params,
          ...querys,
        });
        try{
          const product_description = document.querySelector('.product-detail__accordion-item-wrap > div.product-detail__accordion-item-content > div > section > p:nth-child(1)').innerHTML
          const product_title = document.querySelector('.product-detail__product-summary-wrap > div > h1 > span').innerHTML;
          const vendor = document.querySelector('.product-detail__product-summary-wrap > div > h1 > div > a').innerHTML
          const { product_id } = querys;
          dataReport('product-data', {
            product_id,
            product_url: page_url,
            product_title,
            product_description,
            vendor
          }, 'data-report')
        }catch(e){}
        break;
      default:
        if(pathname.indexOf('/orders/') > -1) {
          const id = params['order[line_items_attributes][0][id]']
          const quantity = params['order[line_items_attributes][0][quantity]']
          const cart = JSON.parse(xhr.responseText)
          const target = cart.lineItems.find(item => item.id == id)

          if(quantity == 0 && !target){
            dataReport('remove-item', {
              page_url,
              attributes,
              page_type,
              page_meta_data,
              line_item_id: id,
              cart
            });
          }

          if(target){
            const { id, name, store, shopPath, quantity, productId: product_id, variantId: variant_id, price } = target
            dataReport('change-quantity', {
              page_url,
              attributes,
              page_type,
              page_meta_data,
              product_name: name,
              store,
              product_shop_path: shopPath,
              quantity,
              line_item_id: id,
              product_id,
              variant_id,
              price,
              cart
            });
          }
        }
        break;
    }
  });
}
