export const sendReportEventRequest = (eventName, info, platform,code = 'treet-bp-widget') => {
    try {
        const currentTime = Date.now()
        const shop_domain = window.location.host
        const body = {
            code,
            eventInfo: JSON.stringify({
                ...info,
                eventName,
                event_ts: currentTime,
                eventTime: currentTime,
                shop_domain
            }),
            platform,
        };
        console.log("body",JSON.stringify(body))
        // return
        fetch(process.env.REPORT_AWS_URL, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((e) => {
            console.log('REPORT EVENT ERROR', e.message);
        });
    } catch (error) {
        console.log('REPORT EVENT ERROR', error.message);
    }
}