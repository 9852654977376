export const SEEL_MODAL_ROOT = 'SEEL_MODAL_ROOT';
export const UPDATE_MODAL_CONFIG = 'UPDATE_MODAL_CONFIG';
export const SEEL_SESSION_ID = 'SEEL_SESSION_ID';


export const MODAL_LEARN_MORE_ONE = {
  blocks: [{
    title: 'Return for any reason',
    rules: ["Item doesn't fit", 'No longer needed', 'Dissatisfied with items', 'Arrived too late'],
  }, {
    title: '7-day return window',
    desc: 'Return in 7 days. Shop with confidence.',
    icon: 'https://cdn.seel.com/assets/images/buyer-protection/widget_icon.svg'
  }, {
    title: 'Easy resolution',
    desc: 'Resolve your return request and get refunded with a few clicks.',
    icon: 'https://cdn.seel.com/assets/images/garmentory/easy_resolution.svg'
  }],
  contents: ["Return Assurance gives you a 7-day return window on otherwise final sale items. If you're unhappy with the purchase for any reason, Seel will buy it back from you for 100% of the purchase price you paid. You can use the ", " to effortless make the return. Shipping and return shipping fees are not included. The cost of the Return Option is non-refundable."]
}

export const MODAL_LEARN_MORE_TWO = {
  blocks: [{
    title: 'Add shipping protection',
    desc: '1-click protect your order',
    icon: 'https://cdn.seel.com/assets/images/return-assurance/widget_icon.svg'
  }, {
    title: 'Guaranteed safe delivery',
    rules: ['Loss', 'Damage', 'Theft'],
  }, {
    title: 'Instant resolution',
    desc: 'Instantly resolve your shipment issues and get a refund or replacement with a few clicks.',
    icon: 'https://cdn.seel.com/assets/images/garmentory/easy_resolution.svg'
  }],
  contents: ['Shipping Protection gives you peace of mind against loss, damage and theft of your package. Within 7 days upon delivery for damage/theft, and 30 days upon fulfilment for loss, buyers can easily request a new shipment or refund for their protected orders through ', ' 100% paid for by Seel.']
}