import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createRoot } from 'react-dom/client';
import { SeelModal } from './seelModal'
import { SEEL_MODAL_ROOT, UPDATE_MODAL_CONFIG, MODAL_LEARN_MORE_ONE, MODAL_LEARN_MORE_TWO, SEEL_SESSION_ID } from './const.js';
import { dispatchCustomEvent } from '../common/events';

import { learnMoreClicked } from '../eventTracking'

import userBehavior from './userBehavior';

function handleClick(type) {
  let config = {}
  if (type == 'ra') {
    config = JSON.parse(JSON.stringify(MODAL_LEARN_MORE_ONE))
  } else if (type == 'bp') {
    config = JSON.parse(JSON.stringify(MODAL_LEARN_MORE_TWO))
  }
  dispatchCustomEvent(UPDATE_MODAL_CONFIG, { status: 'success', data: config })

}

const getSessionId = (key) => {
  const value = sessionStorage.getItem(key);
  if (value) {
    return value
  } else {
    const generated = uuidv4()
    sessionStorage.setItem(key, generated)
    return generated
  }

}

window.SeelSDK = {
  seelSDK: {
    getSessionId: () => getSessionId(SEEL_SESSION_ID),
    showBPPopup: () => {
      const type = 'bp'
      handleClick(type)
      //learn more点击上报
      learnMoreClicked(type)
    },
    showRAPopup: () => {
      const type = 'ra'
      handleClick(type)
      //learn more点击上报
      learnMoreClicked(type)
    },
  }
}

const initializeModal = () => {

  const anchorEl = document.querySelector('body');
  if (!anchorEl) {
    return null
  }
  const seelModalRoot = document.createElement('div');
  seelModalRoot.setAttribute('id', SEEL_MODAL_ROOT);
  anchorEl.append(seelModalRoot);
  let root = createRoot(seelModalRoot)
  root.render(<SeelModal />)
}

try { 
  userBehavior()
  initializeModal()
} catch (err) {
  console.log(err.message)
}


// just for test
// function component(num) {
//   const { seelSDK } = window.SeelSDK;

//   const element = document.createElement('div')
//   element.innerHTML = `learn-more-${num}`
//   element.classList.add(`learn-more-${num}`);
//   element.style.cursor = 'pointer'

//   element.addEventListener('click', () => {
//     if (num == 'one') {
//       seelSDK.showBPPopup()
//     } else {
//       seelSDK.showRAPopup()
//     }
//   })
//   return element;
// }
// document.body.appendChild(component('one'))
// document.body.appendChild(component('two'))

