export const Events = {
  checked: "seel-assurance-checked",
  unchecked: "seel-assurance-unchecked",
}

export const QUOTE_SENDED = 'QUOTE_SENDED';
export const QUOTE_COMPLETED = 'QUOTE_COMPLETED';
export const WIDGET_CHECKED = 'WIDGET_CHECKED';
export const WIDGET_UNCHECKED = 'WIDGET_UNCHECKED';
export const ELIGIBILITY_CHECK_COMPLETED = 'ELIGIBILITY_CHECK_COMPLETED';
export const FREEZE_TOGGLE = 'FREEZE_TOGGLE';

export const dispatchCustomEvent = (name, data = {}) => {
  const event = new CustomEvent(name, {
    detail: data
  });
  console.log(data)
  document.dispatchEvent(event);
}