
import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import './seelModal.css';
import { UPDATE_MODAL_CONFIG } from './const';

import {
    makeBodyObserver,
    removeWidgetClickEventListener
} from '../eventTracking/index'


const customStyles = {
    overlay: {
        zIndex: 999,
        backgroundColor: 'rgb(24 32 38 / 60%)'
    },
    content: window?.innerWidth > 700 ? {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
        padding: '30px',
        borderRadius: '20px',
        overflow: 'hidden'
    } : {
        top: '40px',
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        minWidth: '240px',
        borderRadius: '20px',
        padding: '0',
        paddingTop: '80px',
        paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--sat"),

    },
};
export const SeelModal = ({

}) => {
    const [config, setConfig] = useState({});
    const { blocks, contents = [] } = config;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    useEffect(() => {
        console.log("version 14")
        //监听config改变
        document.addEventListener(UPDATE_MODAL_CONFIG, ({ detail }) => {
            setConfig(detail.data);
            setModalIsOpen(true);
        })

        //设置埋点事件触发监听器
        makeBodyObserver()


        return () => {
            //移除widget checkbox的点击事件监听
            removeWidgetClickEventListener()
        }

    }, [])
    return (
        <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            onRequestClose={() => {
                setModalIsOpen(false);
            }}
            style={customStyles}
        >
            <div className='seel--modal--header' style={window?.innerWidth > 700 ? {
                position: "relative", top: 0, left: 0
            } : {}}>
                <div className='seel--modal--logo'>
                    <img src='https://cdn.seel.com/assets/images/logo/seel_logo.svg' style={{ height: '35px' }} />
                </div>

                <img src="https://cdn.seel.com/assets/images/close_icon.svg" className='seel--modal--close'
                    onClick={() => {
                        setModalIsOpen(false);
                    }} />
                <div className='seel--background--one'></div>
                <div className='seel--background--two'></div>
            </div>


            <div className='seel--modal--blocks' style={window?.innerWidth > 700 ? {} : {
                display: 'flex',
                flexDirection: 'column'
            }}>
                {blocks?.map(({ title, rules, desc, icon }) => (
                    <div className='seel--modal--blocks-section'
                        key={title}
                        style={window?.innerWidth > 700 ? {} : {
                            width: '100%',
                            marginBottom: '15px'
                        }}>
                        <div className='seel--modal--blocks-section-header'>{title}</div>
                        <div className='seel--modal--blocks-section-content' style={{ alignItem: 'flex-start' }}>

                            {desc ? <>
                                <img className='seel--modal--blocks-section-content-img' src={icon} />
                                <div className='seel--modal--blocks-section-content-b'>
                                    <div style={{ textAlign: 'center', lineHeight: '18px', fontWeight: 300, fontSize: '14px', color: '#565656' }}>{desc}</div>
                                </div>
                            </>
                                :
                                <div className='seel--modal--blocks-section-content' style={{ alignItem: 'flex-start' }}>
                                    <div>
                                        {
                                            rules.map((text, idx) => {
                                                return <div key={`${idx}-a`} className='seel--modal--blocks-section-content-text' style={{ width: '100%' }}><img className="seel--modal--blocks-section-content-text-icon" src="https://cdn.seel.com/assets/images/garmentory/checked.svg" />{text}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className='seel--modal--paragraph'>{contents[0]}
                <a target='_blank' className='seel--resolution--center' href='https://claims.seel.com/'>Seel Resolution Center</a>
                {contents[1]}
            </div>
        </Modal>
    )
}
