import { sendReportEventRequest } from "./reportEvent"

export const makeBodyObserver = () => {
    if (window.location.pathname === "/checkout") {
        // 观察器的配置（需要观察什么变动）
        const config = {
            attributes: true,
            childList: true,
            subtree: true
        };

        const cb = () => {
            console.log("observer cb")
            const isLoadedRaWidget = getElementByQuerySelector("#order_add_returns_insurance")
            const isLoadedBpWidget = getElementByQuerySelector("#order_shipping_protection_included")
            if (isLoadedRaWidget && isLoadedBpWidget) {
                console.log("observer callback")
                observer.disconnect()
                //widget show事件上报
                reportWidgetShow()
                //widget checkbox点击事件上报
                setWidgetClickEventListener()

            }
        }
        // 创建一个观察器实例并传入回调函数
        const observer = new MutationObserver(cb);
        console.log("MutationObserver")

        // 以上述配置开始观察目标节点
        observer.observe(getElementByQuerySelector("body"), config);
    }
}

//checkout widget show
const reportWidgetShow = () => {
    const widget_types = ["ra", "bp"]
    widget_types.forEach((item) => {
        const info = getWidgetEventInfo(item)
        console.log("reportWidgetShow", info)
        sendWidgetShowReq(info)
    })

}

//获取widget报价公共参数
const getWidgetEventInfo = (type) => {
    let quote_id_tag;
    let quote_price_tag;
    let product_type = type;
    if (type === "ra") {
        quote_id_tag = "[name='order[shipping_protection_quote_id]']"
        quote_price_tag = "[for='order_add_returns_insurance']+.updating-message"
    } else if (type === "bp") {
        quote_id_tag = "[name='order[shipping_protection_quote_id]']"
        quote_price_tag = "[for='order_shipping_protection_included']+.updating-message"
    }
    return setWidgetShowEventInfo(quote_id_tag, quote_price_tag, product_type)
}

const setWidgetShowEventInfo = (quote_id_tag, quote_price_tag, product_type) => {
    const quote_id = getElementByQuerySelector(quote_id_tag)?.value
    const quote_price = getElementByQuerySelector(quote_price_tag)?.textContent
    const item_subtotal = getElementByQuerySelector(".cart-total-amount")?.textContent
    const res = {
        quote_id,
        quote_price,
        item_subtotal,
        product_type
    }
    console.log("setWidgetShowEventInfo", res)
    return res
}

const getElementByQuerySelector = (tag) => {
    return document.querySelector(tag)
}

const sendWidgetShowReq = (info) => {
    sendReportEventRequest("widget_show", info, "garmentory", "garmentory-widget")
}

//learn more popup
export const learnMoreClicked = (product_type) => {
    const info = {
        product_type
    }
    sendReportEventRequest("learn_more_clicked", info, "garmentory", "garmentory-widget")
}
//获取widget的checkbox
const getRaAndBpWidget = () => {
    const raWidgetCheckbox = getElementByQuerySelector("#order_add_returns_insurance")
    const bpWidgetCheckbox = getElementByQuerySelector("#order_shipping_protection_included")
    return [
        { element: raWidgetCheckbox, type: "ra" },
        { element: bpWidgetCheckbox, type: "bp" }
    ]
}

//给widget checkbox 绑定点击事件监听
const setWidgetClickEventListener = () => {
    const elementList = getRaAndBpWidget()
    // console.log("setWidgetClickEventListener elementList",elementList)
    elementList.forEach((item) => {
        const { element, type } = item
        element.addEventListener("click", () => {
            const checked = element?.checked
            console.log("setWidgetClickEventListener click", checked)
            widgetClickEventListenerFnc(checked, type)
        })
    })
}

//移除widget checkbox 的点击事件监听
export const removeWidgetClickEventListener = () => {
    const isCheckoutPage = window.location.pathname === "/checkout"
    const isLoadedRaWidget = getElementByQuerySelector("#order_add_returns_insurance")
    const isLoadedBpWidget = getElementByQuerySelector("#order_shipping_protection_included")
    if (isCheckoutPage && isLoadedRaWidget && isLoadedBpWidget) {
        const elementList = getRaAndBpWidget()
        // console.log("removeWidgetClickEventListener elementList",elementList)
        elementList.forEach((item) => {
            const { element, type } = item
            element.removeEventListener("click", () => {
                const checked = element?.checked
                widgetClickEventListenerFnc(checked, type)
            })
        })
    }
}

const widgetClickEventListenerFnc = (checked, type) => {
    // console.log("checked",checked)
    if(typeof(checked) === 'boolean'){
        const eventName = checked ? "widget_checked" : "widget_unchecked"
        const info = getWidgetEventInfo(type)
        sendReportEventRequest(eventName, info, "garmentory", "garmentory-widget")
    }
 }